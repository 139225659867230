import styled from 'styled-components';
import { Button } from '../Button';
import { Card } from '../Card';
import { InlineText } from '../Typography';

export const MenuContainer = styled.div`
  flex: 1;
`;

export const Container = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${(props) => props.theme.spacings.sm}px ${(props) => props.theme.spacings.lg}px 0;

  ${MenuContainer} {
    display: none;
  }

  > * {
    flex: 1;
    display: flex;
    flex-direction: row;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}px) {
    & {
      flex-direction: row;
      padding: ${(props) => props.theme.spacings.md}px ${(props) => props.theme.spacings.lg}px;
    }

    ${MenuContainer} {
      display: block;
    }
  }
`;

export const WalletContainer = styled.div`
  flex: 1;
  margin-left: auto;
  display: flex;
  flex-direction: row;

  &:before {
    content: "";
    display: flex;
    flex: 1;
  }
`;

export const DisconnectButton = styled(Button)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
`;

export const WalletCard = styled(Card)`
  padding: ${(props) => props.theme.spacings.xs * 2}px
    ${(props) => props.theme.spacings.sm}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  min-width: 140px;

  img {
    height: 24px;
    width: 25px;
    margin-right: ${(props) => props.theme.spacings.xs}px;
  }

  &:hover {
    ${DisconnectButton} {
      opacity: 1;
    }
  }
`;

export const WalletText = styled(InlineText)`
  display: block;
  line-height: 16px;
`;