import styled from 'styled-components';
import { ThemeColors } from '../../types';
export const Placeholder = styled.div<{ size: number }>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  line-height: ${props => props.size}px;
  display: inline-block;
  background: ${props => props.theme.colors.C4};
  border-radius: 50%;
  text-align: center;
`;

export const Img = styled.img<{ $bg?: keyof ThemeColors }>`
  background: ${props => props.theme.colors[props.$bg || 'C1'] };
  border-radius: 50%;
`;