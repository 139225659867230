import { Account, Contract } from 'near-api-js';
import { FungibleTokenContract } from './types';


export const getFungibleTokenContract = (accountId: Account, contractId: string) => {
  const contract = new Contract(accountId, contractId, {
    changeMethods: ['ft_transfer'],
    viewMethods: ['ft_total_supply', 'ft_balance_of', 'ft_metadata', 'storage_balance_of', 'storage_balance_bounds'],
  }) as FungibleTokenContract;
  
  return contract;
};