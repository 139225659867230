import useSWR from 'swr';
import { toMap } from '../utils/collection';


type CoingeckoToken = {
  id: string
  symbol: string
  name: string
};

type CoingeckoTokensMap = Map<string, CoingeckoToken>;

interface UseCoingeckoTokensMapResult {
  tokenBySymbolMap: CoingeckoTokensMap
  tokenByIdMap: CoingeckoTokensMap
  refresh: () => void
}

const fetcher = async () => {
  const list = await fetch('https://api.coingecko.com/api/v3/coins/list');
  const listResponse: CoingeckoToken[] = await list.json();

  const tokenBySymbolMap = toMap(listResponse, (token) => token.symbol);
  const tokenByIdMap = toMap(listResponse, (token) => token.id);

  return { tokenBySymbolMap, tokenByIdMap };
};


export const useCoingeckoTokensMap = (): UseCoingeckoTokensMapResult => {

  const { data, mutate: refresh } = useSWR('useCoingeckotTokenList', fetcher, { refreshInterval: 100_000_000 });

  if (!data) {
    return {
      tokenBySymbolMap: new Map<string, CoingeckoToken>(),
      tokenByIdMap: new Map<string, CoingeckoToken>(),
      refresh,
    };
  }

  const { tokenBySymbolMap, tokenByIdMap } = data;

  return {
    tokenBySymbolMap,
    tokenByIdMap,
    refresh,
  };
};
