import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: radial-gradient(rgba(128, 152, 194, 0.5) 3.25%, transparent 8.25%) 0px 0px / 15px 15px, radial-gradient(rgb(244, 246, 251) 3%, transparent 3%) 50px 50px rgb(244, 246, 251);
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.colors.C1};
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;