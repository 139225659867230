import useSWR from 'swr';

import { useCoingeckoTokensMap } from './useCoinGeckoTokens';

interface UseCoingeckoPricesMapResult {
  pricesMap: Map<string, number>;
  refresh: () => void;
}

const coingeckoTokenIdsBySymbolMap: { [symbol: string]: string } = {
  ato: 'atocha-coin',
  eth: 'ethereum',
  banana: '', // no CGC
  neko: '',
  gear: '',
  gem: '',
  gold: '',
  pxt: '',
  ref: 'ref-finance',
  ply: 'aurigami',
  uni: 'uniswap',
  flx: 'flux-token',
  solace: 'solace',
  link: 'chainlink',
  grt: 'the-graph',
  gtc: 'gitcoin',
  oct: 'octopus-network',
  abr: 'allbridge',
  cusd: 'celo-dollar',
  usdc: 'usd-coin',
  aurora: 'aurora-near',
  sol: 'solana',
};

export const useCoingeckoPrices = (
  symbols: string[],
): UseCoingeckoPricesMapResult => {
  const { tokenByIdMap, tokenBySymbolMap } = useCoingeckoTokensMap();

  const fetcher = async () => {
    const tokenIds = symbols.map((symbol) => {
      const lowerSymbol = symbol.toLowerCase();

      if (coingeckoTokenIdsBySymbolMap[lowerSymbol] !== undefined) {
        return coingeckoTokenIdsBySymbolMap[lowerSymbol];
      }

      const { id } = tokenBySymbolMap.get(symbol.toLowerCase()) || { id: '' };
      
      return id;
    });

    if (!tokenIds.length) {
      return new Map<string, number>();
    }
    const prices = await fetch(
      `https://api.coingecko.com/api/v3/simple/price?ids=${tokenIds.join(
        ',',
      )}&vs_currencies=usd`,
    );

    const pricesResponse = await prices.json();

    return tokenIds.reduce((acc, id) => {
      const { usd: price } = pricesResponse[id] || { usd: 0 };
      const { symbol } = tokenByIdMap.get(id) || { symbol: '' };

      return acc.set(symbol.toUpperCase(), price);
    }, new Map<string, number>());
  };

  const { data: pricesMap, mutate: refresh } = useSWR(
    `useCoingeckoPrices-${symbols.join(',')}`,
    fetcher,
    {
      refreshInterval: 60_000,
    },
  );

  if (!pricesMap) {
    return { pricesMap: new Map<string, number>(), refresh };
  }

  return { pricesMap, refresh };
};
