import { TokenInfo } from '@tonic-foundation/token-list';
import { Decimal } from 'decimal.js';
import { Account } from 'near-api-js';
import useSWR from 'swr';
import {
  getFungibleTokenContract,
  WRAP_NEAR_CONTRACT_ID,
} from '../api/contracts';
import { useNear, useTokenInfo } from '../contexts';
import { useBalance } from './useBalance';

export const USE_BALANCE_CACHE_PREFIX = 'account-balance-';

export const getTokenBalance = async ({
  account,
  token,
  tokenInfo,
}: {
  account: Account;
  token: string;
  tokenInfo: TokenInfo;
}) => {
  const contract = getFungibleTokenContract(account, token);
  const balance = await contract.ft_balance_of({
    account_id: account.accountId,
  });
  const result = new Decimal(balance).div(10 ** tokenInfo.decimals).toNumber();

  return { token, amount: result };
};

export const useTokenBalance = (token: string) => {
  const { account } = useNear();
  const tokenInfo = useTokenInfo(token);

  const { data: nativeBalance } = useBalance();

  const key = `${USE_BALANCE_CACHE_PREFIX}-${token}-${account?.accountId}`;

  const fetcher = async () => {
    if (!account || !tokenInfo) {
      return 0;
    }

    const { amount } = await getTokenBalance({
      account,
      token,
      tokenInfo,
    });

    return amount;
  };

  const r = useSWR(key, fetcher, { refreshInterval: 30_000 });

  if (typeof r.data === 'number') {
    const addNative = token === WRAP_NEAR_CONTRACT_ID ? nativeBalance || 0 : 0;
    return { ...r, data: r.data + addNative };
  }
  return r;
};
