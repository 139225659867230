import { Account, utils } from 'near-api-js';
import useSWR from 'swr';
import { useNear } from '../contexts';
import { USE_BALANCE_CACHE_PREFIX } from './useTokenBalance';

const LOCKED = 0.05; // TODO: find origin of that lock

export const useBalance = () => {
  const { account, near } = useNear();

  const fetcher = async () => {
    if (account) {
      const acc = new Account(near.connection, account.accountId);
      const balance = await acc.getAccountBalance();
      return Math.max(parseFloat(utils.format.formatNearAmount(balance.available)) - LOCKED, 0);
    }
    return 0;
  };
  return useSWR(`${USE_BALANCE_CACHE_PREFIX}-near-${account?.accountId}`, fetcher);
};