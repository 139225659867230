import { Id } from 'react-toastify';
import { callToast } from '../../contexts';
import { FlexBlock } from '../Layout';
import { Toast } from '../Toast/Toast';
import { FailedButtonsRow, TextButton } from './styles';

const DefaultFailedDescription = ({ onRetry }: { onRetry?: () => void }) => (
  <FlexBlock $alignItems="center" $justifyContent="space-between">
    <span>Failed.</span>
    <FailedButtonsRow>
      <TextButton color="C2">Cancel</TextButton>
      {onRetry && (
        <TextButton color="B1" onClick={onRetry}>
          Try again
        </TextButton>
      )}
    </FailedButtonsRow>
  </FlexBlock>
);

export const notify = ({
  toastId = 'default-toast-id',
  type,
  title,
  description,
  progressOptions = { segments: 2, value: 2 },
  onRetry,
}: {
  toastId?: Id;
  type: 'progress' | 'success' | 'error';
  progressOptions?: { segments: number; value: number };
  title: string;
  description?: string;
  onRetry?: () => void;
}) => {
  callToast(toastId, {
    render: () => (
      <Toast
        type={type}
        progressOptions={progressOptions}
        title={title}
        description={
          description ?? <DefaultFailedDescription onRetry={onRetry} />
        }
      />
    ),
    options: {
      autoClose: type === 'success' ? 5000 : false,
    },
  });
};
