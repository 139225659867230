import styled, { keyframes } from 'styled-components';
import { rgba } from 'polished';
import { Card } from '../Card';
import { Button } from '../Button';

const kf = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ModalContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 101;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  animation: 0.1s ${kf} ease-out;

  background: ${props => rgba(props.theme.colors.C6, 0.8)};
`;

type ModalBodyProps = {
  $width?: string
};

export const ModalBody = styled(Card)<ModalBodyProps>`
  height: auto;
  max-width: 80em;
  max-height: 95vh;
  overflow: auto;
  width: ${(props) => props.$width || 'auto'};
`;

export const ModalContent = styled.div`
  padding: 0;
`;

export const ModalTitle = styled.h3`
  margin: 0;
  color: ${(props) => props.theme.colors.C1};
  padding-right: ${(props) => props.theme.spacings.md}px;
`;

export const ModalTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: ${(props) => props.theme.spacings.lg}px ${(props) => props.theme.spacings.md}px ${(props) => props.theme.spacings.md}px ${(props) => props.theme.spacings.md}px;
`;

export const CloseIcon = styled(Button)`
  cursor: pointer;
  font-weight: normal;
  background-color: ${(props) => props.theme.colors.C4};
`;
