import styled from 'styled-components';
import { InlineText } from '../Typography';

export const Container = styled.footer`
  
  padding: ${(props) => props.theme.spacings.md}px ${props => props.theme.spacings.lg}px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;

  ${InlineText} {
    display: none;
    padding: 0 20px;
    text-align: center;
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}px) {
    ${InlineText} {
      display: inline;
    }
  }
`;

export const SocialLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 25px;

  a {
    display: block;
    margin-right: ${props => props.theme.spacings.md}px;

    &:hover {
      svg path {
        fill: ${props => props.theme.colors.B4};
      }
    }
  }
`;