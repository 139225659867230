import React, { useEffect, useState } from 'react';
import {
  formatNumberToUSFormat,
  getNumberOfDecimalsFromNumber,
  getNumberOfIntegersFromNumber,
  numberWithOneDotRegexp,
} from '../../utils';
import { PlainInput } from './styles';
import { InputProps } from './types';
import { validateDecimal, validateNatural } from './utils';

export const INPUT_FORMATTERS = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  NOP: (e: string, prevValue?: string | number) => e,

  DECIMAL: (v: string | number, prevValue?: string | number) => {
    let value = v ? `${v}`.replaceAll(',', '').replaceAll(' ', '') : `${v}`;

    if (value === '') {
      return value;
    }

    // add decimal if zero with number after it
    if (value[0] === '0' && /^\d+$/.test(value[1])) {
      value = `0.${value.slice(1)}`;
    }

    if (
      validateDecimal(value) &&
      numberWithOneDotRegexp.test(value) &&
      getNumberOfIntegersFromNumber(value) <= 8 &&
      getNumberOfDecimalsFromNumber(value) <= 8
    ) {
      return formatNumberToUSFormat(value);
    }

    return `${prevValue ?? 0}`;
  },
  NATURAL: (v: string, prevValue: string) => {
    if (validateNatural(v) || v === '') {
      return v;
    }
    return prevValue;
  },
};

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const {
      formatter = INPUT_FORMATTERS.NOP,
      size = 8,
      onChange,
      onFocus,
      className,
      placeholder,
      autoFocus = false,
      onBlur,
      disabled = false,
    } = props;

    const [cursor, setCursor] = useState<number | null>(null);

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      const formatted = formatter(e.target.value, props.value);
      if (formatted !== props.value) {
        onChange(formatted);

        const diffInLength = formatted.length - e.target.value.length;

        if (e.target.selectionStart !== null) {
          setCursor(e.target.selectionStart + diffInLength);
        }
      }
    };

    useEffect(() => {
      if (ref) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const input = ref.current;
        if (input) input.setSelectionRange(cursor, cursor);
      }
    }, [ref, cursor, props.value]);

    return (
      <PlainInput
        autoFocus={autoFocus}
        value={props.value}
        ref={ref}
        size={size}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChangeHandler}
        className={className}
        placeholder={placeholder}
      />
    );
  },
);
