import styled from 'styled-components';
import { FlexBlockProps } from './types';

export const Flex = styled.div`
  display: flex;
`;

export const FlexBlock = styled(Flex)<FlexBlockProps>`
  flex-direction: ${(props: FlexBlockProps) => props.$direction || 'row'};
  justify-content: ${(props: FlexBlockProps) =>
    props.$justifyContent || 'normal'};
  align-items: ${(props: FlexBlockProps) => props.$alignItems || 'normal'};
  ${(props: FlexBlockProps) => (props.$flex ? `flex: ${props.$flex};` : '')}
`;

export const FlexFull = styled(FlexBlock)`
    width: 100%;
`;