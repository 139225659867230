import styled from 'styled-components';
import { InlineText } from '../../Typography';
import { WithType } from '../types';
import { ReactComponent as StatusLeft } from './assets/tx-status-left.svg';
import { ReactComponent as StatusRight } from './assets/tx-status-right.svg';

export const IconContainer = styled.div<WithType>`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: ${props => props.theme.colors.C4};
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  div {
    #progress-spin {
      transform-origin: center center;
      animation-name: spin;
      animation-duration: 1000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
  
  svg {
    path {
      stroke: ${props => props.type === 'error' ? props.theme.colors.error : props.theme.colors.C1};
    }
  }
  
`;

export const IconContainerInner = styled.div`
  height: 30px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StatusLeftIcon = styled(StatusLeft)`
  margin-right: auto;
`;
export const StatusRightIcon = styled(StatusRight)`
  margin-left: auto;
`;

export const StatusIcon = styled.div`
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 -6px 0 -7px;
`;

export const StatusSideWrap = styled.div`
  width: 15px;
  height: 30px;
`;

export const Message = styled(InlineText)<{ needMargin: boolean }>`
  margin-bottom: ${props => props.needMargin ? props.theme.spacings.sm : '0'}px;
`;

export const SubButton = styled(InlineText)`
  padding: 0 ${props => props.theme.spacings.xs}px;
`;