import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { PageLayout } from './components';
import { NearProvider, TokensProvider, TransactionManager } from './contexts';

import 'react-toastify/dist/ReactToastify.css';


import './index.css';
import { GlobalAppStyle } from './style';
import { PerkThemeProvider } from './theme';


const SwapPage = React.lazy(() =>
  import('./pages/SwapPage').then((module) => ({ default: module.SwapPage })),
);

function App() {

  return (
    <BrowserRouter>
      <HelmetProvider>
        <NearProvider>
          <PerkThemeProvider>
            <TransactionManager>
              <TokensProvider>
                <GlobalAppStyle />
                <Helmet>
                  <meta charSet="utf-8" />
                  <title>Perk | The Best Liquidity Aggregator Near you.</title>
                </Helmet>

                <PageLayout>
                  <Routes>
                    <Route index element={<SwapPage />} />
                    <Route path='*' element={<Navigate to="/" replace />} />
                  </Routes>
                </PageLayout>

                <ToastContainer position='bottom-left' icon={false} hideProgressBar pauseOnFocusLoss={false} closeButton={false} />
              </TokensProvider>
            </TransactionManager>
          </PerkThemeProvider>
        </NearProvider>
      </HelmetProvider>
    </BrowserRouter>
  );
}

export default App;
