import { useRef, forwardRef, useImperativeHandle, useCallback } from 'react';
import { useInterval } from '../../hooks/useInterval';
import { ReactComponent as Circle } from './assets/circle.svg';
import {
  PieTimerCircleInside,
  PieTimerContainer,
} from './styles';
import { PieTimerProps, PieTimerInstance } from './types';



export const PieTimer = forwardRef<PieTimerInstance, PieTimerProps>((params, ref) => {
  const { duration, size = 32, callback, disabled } = params;
  

  const animationResetter = useRef<HTMLDivElement | null>(null);

  const restartAnimation = () => {

    if (animationResetter.current) {
      animationResetter.current.style.animationName = 'none';

      requestAnimationFrame(() => {
        setTimeout(() => {
          if (animationResetter.current) {
            animationResetter.current.style.animationName = '';
          }
        }, 0);
      });
    }
  };

  const { reset } = useInterval(() => callback(), duration * 1000);

  const resetTimer = useCallback( () => {
    reset();
    restartAnimation();
  }, [reset]);

  useImperativeHandle(ref, () => ({
    reset: resetTimer,
  }), [resetTimer]);

  return (
    <PieTimerContainer
      $size={size}
      $duration={duration}
      $disabled={disabled}
      onClick={() => {
        resetTimer();
        callback();
      }}
    >
      {/* <div className="pie-timer-filled" /> */}
      <div ref={animationResetter} className="pie-timer-fill-animation" />
      <PieTimerCircleInside />
      <div className="svg-overlay">
        <Circle />
      </div>
    </PieTimerContainer>
  );
});
