import { FlexBlock } from '../../Layout';
import { Message, SubButton } from './styles';
import { FailedNotificationProps } from './types';

export const FailedTransactionNotification: React.FC<FailedNotificationProps> = (props) => {
  const { message = 'Transaction Failed', onRetry, txId } = props;
  return (
    <FlexBlock $direction='column' $justifyContent='flex-end'>
      <Message needMargin={!!(onRetry || txId)} $textAlign='right' $fontWeight={600}>
        {message}
      </Message>
      {!!(onRetry || txId) &&
        <FlexBlock $direction='row' $justifyContent='flex-end'>
          {txId &&
            <SubButton as="a" $fontSize="H5" $color="B1" $fontWeight={600} href={`https://explorer.near.org/transactions/${txId}`}>Explorer</SubButton>
          }
          {onRetry &&
            <SubButton $fontSize="H5" $color="B1" $fontWeight={600} onClick={onRetry}>Try again</SubButton>
          }
        </FlexBlock>
      }
    </FlexBlock>
  );
};