import styled from 'styled-components';

export const PlainInput = styled.input`
  background: none;
  border: none;
  font-family: ${props => props.theme.fonts.primary};
  outline: none;
  color: ${props => props.theme.colors.C2};

  &::placeholder {
    color: ${props => props.theme.colors.C3};
  }
`;