import { useSWRConfig } from 'swr';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CACHE_MAP = new Map<string, any>();


export const useMatchMutate = () => {
  const { cache, mutate } = useSWRConfig();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (matchers: string[], ...args: any[]) => {
    if (!(cache instanceof Map)) {
      throw new Error('matchMutate requires the cache provider to be a Map instance');
    }

    console.log('matchers:', matchers, [...cache.keys()]);

    const keysToUpdate: string[] = [...cache.keys()].filter(key => {
      const match = matchers.some(matcher => key.includes(matcher));
      return !!match;
    });

    [...CACHE_MAP.keys()].filter(key => {
      const match = matchers.some(matcher => key.includes(matcher));
      if (match) {
        CACHE_MAP.delete(key);
      }
    });



    const mutations = keysToUpdate.map((key) => mutate(key, ...args));
    return Promise.all(mutations);
  };
};