import './globals';
import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
// import * as Sentry from '@sentry/react';
// import { BrowserTracing } from '@sentry/tracing';
import { Decimal } from 'decimal.js';
import App from './App';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (process.env.NODE_ENV !== 'development') {
  ReactGA.initialize('G-YT8281FGE0');

  // Sentry.init({
  //   dsn: 'https://6dbbc1fe03aa40ff9daad90ca53ec3bc@o1177985.ingest.sentry.io/6657665',
  //   integrations: [new BrowserTracing()],

  //   // Set tracesSampleRate to 1.0 to capture 100%
  //   // of transactions for performance monitoring.
  //   // We recommend adjusting this value in production
  //   tracesSampleRate: 0.3,
  // });
}

Decimal.config({ precision: 36, rounding: 8, toExpNeg: -40, toExpPos: 40 });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
    <App />,
);
