import { Container, NavLink } from './styles';

import { ReactComponent as Stock } from './assets/stock.svg';
import { ReactComponent as Docs } from './assets/docs.svg';

export const NavBar = () => {
  return (
    <Container>
      <NavLink to="/">

        <Stock />
        Trade
      </NavLink>
      <NavLink as="a" href="https://docs.perk.money/" target="_blank" rel="noopener noreferrer">
        <Docs />
        Docs
      </NavLink>
    </Container>
  );
};