import { useState } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { InlineText, Text } from '../Typography';
import { FlexBlock } from '../Layout';
import { PrivacyPolicy } from '../../modals';
import { Container, SocialLinks } from './styles';
import { ReactComponent as FooterLogo } from './assets/footer-logo.svg';

import { ReactComponent as Twitter } from './assets/twitter.svg';
import { ReactComponent as Discord } from './assets/discord.svg';

const OpenPrivacyPopupButton = styled(Text)`
  font-size: ${props => props.theme.typography.H5}px;
  cursor: pointer;
  color: ${props => rgba(props.theme.colors.C1, 0.35)};
  margin-left: ${props => props.theme.spacings.md}px;
`;

export const Footer = () => {
  const [isPrivacyPolicyModalOpen, setIsPrivacyPolicyModalOpen] =
    useState(false);

  return (
    <Container>
      <FlexBlock $alignItems={'center'}>
        <FooterLogo />
        <OpenPrivacyPopupButton  onClick={() => setIsPrivacyPolicyModalOpen(true)}>
          Privacy Policy
        </OpenPrivacyPopupButton>
      </FlexBlock>

      <InlineText $fontSize="H5" $color="C3">
        Swap and liquidity aggregator with all the Perks.
      </InlineText>
      <SocialLinks>
        <a
          href="https://twitter.com/Perk_Money"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Twitter />
        </a>
        <a
          href="https://discord.gg/SsW9zMubxK"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Discord />
        </a>
      </SocialLinks>

      <PrivacyPolicy
        isOpen={isPrivacyPolicyModalOpen}
        close={() => setIsPrivacyPolicyModalOpen(false)}
      />
    </Container>
  );
};
