import {
  setupWalletSelector,
  WalletSelector,
} from '@near-wallet-selector/core';
import {
  setupModal,
  WalletSelectorModal,
} from '@near-wallet-selector/modal-ui';

import { setupNearWallet } from '@near-wallet-selector/near-wallet';
import { setupMyNearWallet } from '@near-wallet-selector/my-near-wallet';
import { setupSender } from '@near-wallet-selector/sender';
import { setupMathWallet } from '@near-wallet-selector/math-wallet';
import { setupNightly } from '@near-wallet-selector/nightly';
import { setupMeteorWallet } from '@near-wallet-selector/meteor-wallet';
import { setupLedger } from '@near-wallet-selector/ledger';
import { setupNearFi } from '@near-wallet-selector/nearfi';
import { setupCoin98Wallet } from '@near-wallet-selector/coin98-wallet';

import { connect, ConnectConfig, keyStores, Near, Account } from 'near-api-js';

import { createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga4';
import { trimAccountId } from '../../utils/wallet';
import { Loader } from '../../components';
import { NearProviderContext, NearProviderProps } from './types';

const NearContext = createContext<NearProviderContext | undefined>(undefined);

const config: ConnectConfig = {
  networkId: 'mainnet',
  nodeUrl: 'https://rpc.mainnet.near.org',
  walletUrl: 'https://wallet.near.org',
  helperUrl: 'https://helper.mainnet.near.org',
  headers: {},
  deps: { keyStore: new keyStores.BrowserLocalStorageKeyStore() },
};

const WALLET_CONNECTION_REQUEST_KEY = 'wallet_connection_requested';
const WALLET_DISCONNECTION_REQUEST_KEY = 'wallet_disconnection_requested';

export const NearProvider: React.FC<NearProviderProps> = ({ children }) => {
  const [nearClient, setNearClient] = useState<Near | undefined>();
  const [walletSelector, setWalletSelector] = useState<
  WalletSelector | undefined
  >();
  const [walletIcon, setWalletIcon] = useState('');
  const [account, setAccount] = useState<Account | undefined>();
  const [modal, setModal] = useState<WalletSelectorModal | undefined>();
  const [isUserSignedIn, setUserSignedIn] = useState(
    walletSelector?.isSignedIn() || false,
  );

  useEffect(() => {
    const init = async () => {
      const ws = await setupWalletSelector({
        network: 'mainnet',
        debug: true,
        modules: [
          setupNearWallet(),
          setupMyNearWallet(),
          setupSender(),
          setupMathWallet(),
          setupNightly(),
          setupMeteorWallet(),
          setupLedger(),
          setupNearFi(),
          setupCoin98Wallet(),
          // setupWalletConnect({
          //   projectId: 'c4f79cc...',
          //   metadata: {
          //     name: 'NEAR Wallet Selector',
          //     description: 'Example dApp used by NEAR Wallet Selector',
          //     url: 'https://github.com/near/wallet-selector',
          //     icons: ['https://avatars.githubusercontent.com/u/37784886'],
          //   },
          // }),
          // setupNightlyConnect({
          //   url: 'wss://relay.nightly.app/app',
          //   appMetadata: {
          //     additionalInfo: '',
          //     application: 'NEAR Wallet Selector',
          //     description: 'Example dApp used by NEAR Wallet Selector',
          //     icon: 'https://near.org/wp-content/uploads/2020/09/cropped-favicon-192x192.png',
          //   },
          // }),
        ],
      });

      const m = setupModal(ws, {
        contractId: 'wrap.near',
        theme: 'light',
        description: 'Setup wallet',
      });
      setModal(m);
      const near = await connect(config);
      setNearClient(near);
      ws.store.observable.subscribe((state) => {
        if (ws.isSignedIn()) {
          localStorage.removeItem(WALLET_DISCONNECTION_REQUEST_KEY);
          const isUserRequested =
            localStorage.getItem(WALLET_CONNECTION_REQUEST_KEY) === 'true';
          if (isUserRequested) {
            ReactGA.event({
              action: 'connected_wallet',
              category: 'common',
            });

            toast.success(
              `Wallet connected: ${trimAccountId(state.accounts[0].accountId)}`,
            );

            localStorage.removeItem(WALLET_CONNECTION_REQUEST_KEY);
          }
          setUserSignedIn(true);
          setAccount(new Account(near.connection, state.accounts[0].accountId));
          ws.wallet().then((w) => {
            setWalletIcon(w.metadata.iconUrl);
          });
        } else {
          localStorage.removeItem(WALLET_CONNECTION_REQUEST_KEY);
          const isUserRequested =
            localStorage.getItem(WALLET_DISCONNECTION_REQUEST_KEY) === 'true';
          if (isUserRequested) {
            ReactGA.event({
              action: 'disconnected_wallet',
              category: 'common',
            });
            toast.success('Wallet succesfully disconnected');
            localStorage.removeItem(WALLET_DISCONNECTION_REQUEST_KEY);
          }
          setUserSignedIn(false);
          setAccount(undefined);
          setWalletIcon('');
        }
      });
      setWalletSelector(ws);
    };

    init();
  }, []);

  if (!nearClient || !walletSelector || !modal) {
    return <Loader />;
  }

  const requestSignIn = async () => {
    localStorage.setItem(WALLET_CONNECTION_REQUEST_KEY, 'true');
    modal.show();
  }; // Don't need to update state because page reloading

  const signOut = async () => {
    localStorage.setItem(WALLET_DISCONNECTION_REQUEST_KEY, 'true');
    (await walletSelector.wallet()).signOut();
  };


  return (
    <NearContext.Provider
      value={{
        account,
        near: nearClient,
        walletSelector,
        isUserSignedIn,
        requestSignIn,
        signOut,
        modal,
        walletIcon,
      }}
    >
      {children}
    </NearContext.Provider>
  );
};

export const useNear = () => {
  const ctx = useContext(NearContext);
  if (!ctx) {
    throw new Error('useWallet must be used within a NearProvider');
  }
  return ctx;
};
