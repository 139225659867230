import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { TokenListProvider, TokenInfo } from '@tonic-foundation/token-list';
import { Loader } from '../../components';
import { toMap } from '../../utils/collection';
import { WRAP_NEAR_CONTRACT_ID } from '../../api/contracts';
import { TokensProviderContext } from './types';

const Context = createContext<TokensProviderContext | undefined>(undefined);



export const TokensProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [tokenList, setTokenList] = useState(new Map<string, TokenInfo>());
  useEffect(() => {
    const init = async () => {
      const tokens = await new TokenListProvider().resolve();
      const list = tokens.filterByNearEnv('mainnet').getList();
      const processedList = list.map(token => {
        if (token.address === WRAP_NEAR_CONTRACT_ID) {
          token.name = 'NEAR';
          token.symbol = 'NEAR';
        } 
        return token;
      });
      const tokensMap = toMap(processedList, (t) => t.address);
      setTokenList(tokensMap);
    };
    init();
  }, []);

  if (!tokenList.size) {
    return <Loader />;
  }
  return <Context.Provider value={{ tokens: tokenList }}>{children}</Context.Provider>;
};


export const useTokens = () => {
  const ctx = useContext(Context);
  if (!ctx) {
    throw new Error('useTokens must be used within a TokensProvider');
  }
  return ctx.tokens;
};

export const useTokenInfo = (tokenAddress: string) => {
  const tokens = useTokens();
  return tokens.get(tokenAddress);
};