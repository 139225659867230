import { AnimatedCircle } from './styles';

const SpinningLoader = () => {
  return (
    <div>
      <AnimatedCircle />
    </div>
  );
};

export { SpinningLoader };
