import { Footer } from '../Footer';
import { Header } from '../Header';
import { Container, Content } from './styles';
import { PageLayoutProps } from './types';

export const PageLayout: React.FC<PageLayoutProps> = ({ children }) => {
  return (
    <Container>
      <Header />
      <Content>
        {children}
      </Content>
      <Footer />
    </Container>
  );
};
