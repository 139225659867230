import React, { useEffect } from 'react';
import { Portal } from 'react-portal';

import {
  CloseIcon, ModalBody, ModalContainer, ModalContent, ModalTitle,
  ModalTitleContainer,
} from './styles';
import { ModalCommon, ModalProps } from './types';

export const ModalTitleBlock: React.FC<ModalCommon> = (props) => {
  const { title, onClose } = props;

  return (
    <ModalTitleContainer>
      <ModalTitle>{title}</ModalTitle>
      <CloseIcon onClick={onClose}>
        Esc
      </CloseIcon>
    </ModalTitleContainer>
  );
};

export const Modal: React.FC<ModalProps> = (props) => {
  const {
    open,
    onClose,
    children,
    title,
    width,
  } = props;


  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [onClose]);

  useEffect(() => {
    if (open) {
      document.body.classList.add('modal-open');
    }

    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [open, onClose]);

  if (!open) {
    return null;
  }


  return (
    <Portal>
      <ModalContainer onClick={onClose} >
        <ModalBody
          onClick={(e) => e.stopPropagation()}
          $width={width}
        >
          <ModalContent>
            <>
              {title && <ModalTitleBlock title={title} onClose={onClose} />}
              {children}
            </>
          </ModalContent>
        </ModalBody>
      </ModalContainer>
    </Portal>
  );
};
