import { useTokenInfo } from '../../contexts';
import { WRAP_NEAR_CONTRACT_ID } from '../../api/contracts';
import NearLogo from '../../assets/near.png';
import { Img, Placeholder } from './style';
import { TokenIconProps } from './types';


export const TokenIcon: React.FC<TokenIconProps> = (props) => {
  const { tokenAddress, size = 24, className } = props;
  const tokenInfo = useTokenInfo(tokenAddress);

  const tokenLogo = tokenAddress === WRAP_NEAR_CONTRACT_ID ? NearLogo : tokenInfo?.logoURI;

  if (!tokenInfo) {
    return <Placeholder size={size} >?</Placeholder>;
  }

  return (
    <Img
      $bg={tokenAddress === WRAP_NEAR_CONTRACT_ID ? 'C0' : undefined}
      className={className}
      width={size}
      height={size}
      src={tokenLogo}
      alt={tokenAddress}
    />
  );
};