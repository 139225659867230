const THOUSAND = 1_000;
const MILLION = 1_000_000;

export const stripDigitPlaces = (
  num: number | string,
  stripToPlaces = 2,
  stripToBigPlaces?: number,
): string | number => {
  const reg = new RegExp(
    `^((\\-|)[0-9]{1,21}\\.[0-9]{0,${stripToPlaces}})|[0-9]{1,21}`,
  );
  const regWithE = /e/g;

  if (stripToBigPlaces && stripToBigPlaces > 1) {
    return Math.floor((+num + 1) / stripToBigPlaces) * stripToBigPlaces;
  }

  const stringFromNumber = (+num).toString();
  if (regWithE.test(stringFromNumber)) {
    return parseFloat(stringFromNumber).toFixed(stripToPlaces);
  }

  const regResult = stringFromNumber.match(reg);

  let strippedNumber;

  if (regResult !== null && regResult[0].endsWith('.')) {
    strippedNumber = regResult[0].slice(0, regResult[0].length - 1);
  } else {
    strippedNumber = regResult !== null ? regResult[0] : num;
  }

  if (num < 0 && strippedNumber > 0)
    strippedNumber = String(+strippedNumber * -1);

  return strippedNumber;
};

// formatNumberToUSFormat - this function takes number or string, then it converts it to string anyway, and then decide
// — if our number has dot "." (is it number with fractional part or not) and then place commas by one of two regexes,
// depending on is our number has float part or not, and return us-formatted number (e.g. 1,000 etc.)

export const formatNumberToUSFormat = (
  numberToFormat: number | string | null,
) => {
  const stringNumber = numberToFormat === null ? '' : numberToFormat.toString();

  return stringNumber.match(/\./g)
    ? stringNumber.replace(/\d(?=(\d{3})+\.)/g, '$& ')
    : stringNumber.replace(/\d(?=(\d{3})+$)/g, '$& ');
};

export const stripByAmount = (
  number: number | string,
  maxDecimals: number | null = null,
) => {
  if (!number) {
    return 0;
  }

  const absNumber = Math.abs(+number);
  let decimals = 8;

  if (absNumber < 0.01) {
    decimals = 8;
  } else if (absNumber < 1) {
    decimals = 5;
  } else if (absNumber < 10) {
    decimals = 4;
  } else if (absNumber < 100) {
    decimals = 3;
  } else if (absNumber < 1000) {
    decimals = 2;
  } else if (absNumber < 10000) {
    decimals = 1;
  } else {
    decimals = 0;
  }

  if (maxDecimals !== null) {
    decimals = Math.min(maxDecimals, decimals);
  }

  return stripDigitPlaces(number, decimals);
};

export const stripToMillions = (number: number | string) => {
  return `${formatNumberToUSFormat(stripDigitPlaces(+number / MILLION, 2))}m`;
};

export const stripToThousands = (number: number | string) => {
  return `${formatNumberToUSFormat(stripDigitPlaces(+number / THOUSAND, 2))}k`;
};

export const stripDecimalsAndFormat = (
  number: number | string,
  maxDecimals: number | null = null,
) => formatNumberToUSFormat(stripByAmount(number, maxDecimals));

export const stripByAmountAndFormat = (
  number: number | string,
  maxDecimals: number | null = null,
) => {
  if (number >= MILLION) {
    return stripToMillions(number);
  }

  if (number >= THOUSAND) {
    return stripToThousands(number);
  }

  return stripDecimalsAndFormat(number, maxDecimals);
};

export const parseFormattedNumber = (formattedNumber: string) => {
  // TODO
  return parseFloat(formattedNumber.replace(/[\s]/g, '').replace(',', '.'));
};

export const numberWithOneDotRegexp = /^(?!0\d)\d+(?:\.\d*?)?$/;

export const getNumberOfDecimalsFromNumber = (number: number | string) => {
  if (!number) {
    return 0;
  }

  let n = number;

  if (String(number).includes('e-')) {
    n = stripDigitPlaces(n, +String(n).split('e-')[1]);
  }
  const splittedNumber = String(n).split('.');

  if (splittedNumber.length === 1) {
    return 0;
  }

  return splittedNumber[1].length;
};

export const getNumberOfIntegersFromNumber = (number: number | string) => {
  if (!number) {
    return 0;
  }

  const splittedNumber = String(number).split('.');

  return splittedNumber[0].length;
};
