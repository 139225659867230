import { WRAP_NEAR_CONTRACT_ID } from '../../api/contracts';
import { useNear } from '../../contexts';
import { useTokenBalance } from '../../hooks';
import { stripByAmount } from '../../utils';
import { trimAccountId } from '../../utils/wallet';
import { NavBar } from '../NavBar';
import { WithConnectedWallet } from '../WithConnectedWallet';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import NearIcon from '../../assets/near.png';
import {
  Container,
  DisconnectButton,
  MenuContainer,
  WalletCard,
  WalletContainer,
  WalletText,
} from './styles';

export const Header: React.FC = () => {
  const { account, signOut, walletIcon } = useNear();

  const { data: balance } = useTokenBalance(WRAP_NEAR_CONTRACT_ID);
  const balanceFormatted = !balance ? '0.00000' : stripByAmount(balance, 5);

  return (
    <Container>
      <a href="https://perk.money" target="_blank" rel="noopener noreferrer">
        <Logo />
      </a>
      <MenuContainer>
        <NavBar />
      </MenuContainer>
      <div>
        <WalletContainer>
          <WithConnectedWallet>
            {account ? (
              <WalletCard>
                <img src={walletIcon || NearIcon} alt="N" />
                <div>
                  <div>
                    <WalletText $fontWeight={600} $fontSize="H5">
                      {balanceFormatted} NEAR
                    </WalletText>
                  </div>
                  <div>
                    <WalletText $fontWeight={400} $fontSize="H5" $color="C3">
                      {trimAccountId(account.accountId)}
                    </WalletText>
                  </div>
                </div>

                <DisconnectButton onClick={signOut}>
                  Disconnect
                </DisconnectButton>
              </WalletCard>
            ) : null}
          </WithConnectedWallet>
        </WalletContainer>
      </div>
    </Container>
  );
};
