import { Button } from '../Button';
import { useNear } from '../../contexts';
import { WithConnectedWalletProps } from './types';

import { ReactComponent as WalletIcon } from './assets/wallet.svg';

export const WithConnectedWallet: React.FC<WithConnectedWalletProps> = (props) => {
  const { width, size, children, margin } = props;
  const { isUserSignedIn, requestSignIn } = useNear();
  if (!isUserSignedIn) {
    return (
      <Button $margin={margin} $width={width} $size={size} onClick={requestSignIn}>
        <WalletIcon /> &nbsp;&nbsp;Connect Wallet
      </Button>
    );
  }
  return <>{children}</>;
};