import React from 'react';
import { IconProps, NotificationType } from '../types';
import {
  IconContainer,
  StatusLeftIcon,
  StatusRightIcon,
  IconContainerInner,
  StatusIcon,
  StatusSideWrap,
} from './styles';

import { ReactComponent as Error } from './assets/error.svg';
import { ReactComponent as Pending } from './assets/pending.svg';
import { ReactComponent as Success } from './assets/success.svg';

const resolveIconComponent = (status: NotificationType) => {
  switch (status) {
    case 'success':
      return Success;
    case 'error':
      return Error;
    default:
      return Pending;
  }
};

export const InfoIcon: React.FC<IconProps> = (props) => {
  const { step, type } = props;

  const Icon = resolveIconComponent(type);
  return (
    <IconContainer type={type}>
      <IconContainerInner>
        <StatusSideWrap>{step > 1 && <StatusLeftIcon />}</StatusSideWrap>
        <StatusIcon id={type === 'pending' ? 'progress-spin' : ''}>
          <Icon />
        </StatusIcon>
        <StatusSideWrap>
          <StatusRightIcon />
        </StatusSideWrap>
      </IconContainerInner>
    </IconContainer>
  );
};
