import styled from 'styled-components';
import { Text } from '../../components';
import { ModalTitleContainer } from '../../components/Modal/styles';

const Title = styled((props) => (
  <Text $fontWeight={700} $fontSize="H2" {...props} />
))`
  margin-bottom: 12px;
`;

const SubTitle = styled((props) => <Title {...props} />)`
  font-size: 16px;
`;

const StyledText = styled((props) => <Text $fontSize="H5" {...props} />)`
  margin-bottom: 12px;
  letter-spacing: 0.2px;
  line-height: 150%;
`;

const StyledModalTitleContainer = styled(ModalTitleContainer)`
  margin-left: 0;
`;

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}px) {
    max-width: 720px;
  }

  padding: ${(props) => props.theme.spacings.md}px;
`;

export { Title, SubTitle, StyledText, StyledModalTitleContainer, ModalContentContainer };