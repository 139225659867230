import { Transaction } from '@near-wallet-selector/core';
import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useRef,
  useContext,
} from 'react';
import { toast, Id, ToastOptions, ToastContent } from 'react-toastify';
import { useNear } from '../NearProvider';
import { FailedTransactionNotification, InfoIcon } from '../../components';
import { useMatchMutate } from '../../hooks';
import { SignTransactionParams, TransactionManagerContext } from './types';

const Context = createContext<TransactionManagerContext>({
  signAndSendTransactions: async () => {},
});

export const callToast = (
  toastId: Id,
  {
    render,
    options = {},
  }: { render: ToastContent<unknown>; options?: ToastOptions },
) => {
  if (toast.isActive(toastId)) {
    toast.update(toastId, {
      render,
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 3000,
      ...options,
    });
  } else {
    toast(render, {
      toastId,
      position: toast.POSITION.BOTTOM_LEFT,
      hideProgressBar: true,
      autoClose: 3000,
      ...options,
    });
  }
};

export const TransactionManager: React.FC<PropsWithChildren> = (props) => {
  const { walletSelector } = useNear();
  const updateCachesFn = useMatchMutate();

  const notification = useRef<Id | null>(null);

  const signTransactions = useCallback(
    async (transactions: Transaction[], params?: SignTransactionParams) => {
      const {
        successMessage = 'Transaction confirmed',
        processingMessage = 'Signing transactions...',
        updateCaches = [],
        onRetry = null,
      } = params || {};
      if (notification.current && toast.isActive(notification.current)) {
        toast.error('Another notification is already in progress');
        throw new Error('Another notification is already in progress');
      }
      const w = await walletSelector.wallet();
      notification.current = toast(processingMessage, {
        type: 'info',
        autoClose: false,
        icon: () => <InfoIcon step={1} type="pending" />,
        closeOnClick: false,
      });
      try {
        const txResult = await w.signAndSendTransactions({ transactions });
        if (txResult) {
          toast.update(notification.current, {
            render: successMessage,
            type: 'success',
            icon: () => <InfoIcon step={2} type="success" />,
            autoClose: 5_000,
          });
          notification.current = null;
        }
        if (updateCaches) {
          updateCachesFn(updateCaches);
        }
        return txResult;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        // If tx failed or user cancelled the transaction
        console.log('Error sign:', e);
        if (notification.current) {
          const errorText = e.toString().toLowerCase();
          const isUserReject = errorText.includes('user reject');
          console.log('errorText:', errorText);
          if (isUserReject) {
            toast.update(notification.current, {
              render: (
                <FailedTransactionNotification
                  message="Transaction cancelled"
                  onRetry={() => signTransactions(transactions, params)}
                />
              ),
              icon: () => <InfoIcon step={2} type="error" />,
              type: 'error',
              autoClose: 5_000,
            });
          } else {
            toast.update(notification.current, {
              render: (
                <FailedTransactionNotification
                  message="Transaction failed"
                  onRetry={
                    onRetry !== undefined
                      ? onRetry
                      : () => {
                        signTransactions(transactions, params);
                      }
                  }
                />
              ),
              icon: () => <InfoIcon step={2} type="error" />,
              type: 'error',
              autoClose: false,
            });
          }
        }
        notification.current = null;

        if (updateCaches) {
          updateCachesFn(updateCaches);
        }
        console.warn('Unexpected error:', e);
      }
    },
    [walletSelector, updateCachesFn],
  );
  return (
    <Context.Provider value={{ signAndSendTransactions: signTransactions }}>
      {props.children}
    </Context.Provider>
  );
};

export const useTransactionManager = () => useContext(Context);
