import { Modal } from '../../components';
import { CloseIcon } from '../../components/Modal/styles';
import {
  Title,
  SubTitle,
  StyledText,
  StyledModalTitleContainer,
  ModalContentContainer,
} from './styles';

const PrivacyPolicy = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) => {
  return (
    <Modal open={isOpen} onClose={close}>
      <ModalContentContainer>
        <StyledModalTitleContainer>
          <Title>Privacy Policy</Title>
          <CloseIcon onClick={close}>Esc</CloseIcon>
        </StyledModalTitleContainer>
        <StyledText>
          Effective date: September 28, 2022 Perk Inc. ("us", "we", or "our")
          operates the{' '}
          <a href="https://app.perk.money/">https://app.perk.money/</a> website
          (the "Service"). This page informs you of our policies regarding the
          collection, use, and disclosure of personal data when you use our
          Service and the choices you have associated with that data. We use
          your data to provide and improve the Service. By using the Service,
          you agree to the collection and use of information in accordance with
          this policy. Unless otherwise defined in this Privacy Policy, terms
          used in this Privacy Policy have the same meanings as in our Terms and
          Conditions, accessible from{' '}
          <a href="https://app.perk.money/">https://app.perk.money/</a>
        </StyledText>
        <Title>Information Collection And Use</Title>
        <StyledText>
          We collect several different types of information for various purposes
          to provide and improve our Service to you.
        </StyledText>

        <Title>Types of Data Collected</Title>
        <SubTitle>- Personal Data</SubTitle>
        <StyledText>
          While using our Service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you ("Personal Data"). Personally identifiable information
          may include, but is not limited to:
        </StyledText>

        <ul>
          <li>
            <StyledText>Email address</StyledText>
          </li>
          <li>
            <StyledText>Cookies and Usage Data</StyledText>
          </li>
        </ul>
        <SubTitle>- Usage Data</SubTitle>
        <StyledText>
          We may also collect information how the Service is accessed and used
          ("Usage Data"). This Usage Data may include information such as your
          computer's Internet Protocol address (e.g. IP address), browser type,
          browser version, the pages of our Service that you visit, the time and
          date of your visit, the time spent on those pages, unique device
          identifiers and other diagnostic data.
        </StyledText>
        <SubTitle>- Tracking & Cookies Data</SubTitle>
        <StyledText>
          We use cookies and similar tracking technologies to track the activity
          on our Service and hold certain information. Cookies are files with
          small amount of data which may include an anonymous unique identifier.
          Cookies are sent to your browser from a website and stored on your
          device. Tracking technologies also used are beacons, tags, and scripts
          to collect and track information and to improve and analyze our
          Service. You can instruct your browser to refuse all cookies or to
          indicate when a cookie is being sent. However, if you do not accept
          cookies, you may not be able to use some portions of our Service.
        </StyledText>
        <SubTitle>- Examples of Cookies we use:</SubTitle>

        <ul>
          <li>
            <StyledText>
              <b>Session Cookies.</b> We use Session Cookies to operate our
              Service.
            </StyledText>
          </li>
          <li>
            <StyledText>
              <b>Preference Cookies.</b> We use Preference Cookies to remember
              your preferences and various settings.
            </StyledText>
          </li>
          <li>
            <StyledText>
              <b>Security Cookies.</b> We use Security Cookies for security
              purposes.
            </StyledText>
          </li>
        </ul>

        <Title>Use of Data</Title>
        <StyledText>
          Perk Inc. uses the collected data for various purposes:
        </StyledText>
        <ul>
          <li>
            <StyledText>To provide and maintain the Service</StyledText>
          </li>
          <li>
            <StyledText>To notify you about changes to our Service</StyledText>
          </li>
          <li>
            <StyledText>
              To allow you to participate in interactive features of our Service
              when you choose to do so
            </StyledText>
          </li>
          <li>
            <StyledText>To provide customer care and support</StyledText>
          </li>
          <li>
            <StyledText>
              To provide analysis or valuable information so that we can improve
              the Service
            </StyledText>
          </li>
          <li>
            <StyledText>To monitor the usage of the Service</StyledText>
          </li>
          <li>
            <StyledText>
              To detect, prevent and address technical issues
            </StyledText>
          </li>
        </ul>

        <Title>Transfer Of Data</Title>

        <StyledText>
          Your information, including Personal Data, may be transferred to — and
          maintained on — computers located outside of your state, province,
          country or other governmental jurisdiction where the data protection
          laws may differ than those from your jurisdiction.
        </StyledText>
        <StyledText>
          If you are located outside United States and choose to provide
          information to us, please note that we transfer the data, including
          Personal Data, to United States and process it there.
        </StyledText>
        <StyledText>
          Your consent to this Privacy Policy followed by your submission of
          such information represents your agreement to that transfer. Perk Inc.
          will take all steps reasonably necessary to ensure that your data is
          treated securely and in accordance with this Privacy Policy and no
          transfer of your Personal Data will take place to an organization or a
          country unless there are adequate controls in place including the
          security of your data and other personal information.
        </StyledText>

        <Title>Disclosure Of Data</Title>

        <SubTitle>Legal Requirements</SubTitle>
        <StyledText>
          Perk Inc. may disclose your Personal Data in the good faith belief
          that such action is necessary to:
        </StyledText>

        <ul>
          <li>
            <StyledText>To comply with a legal obligation</StyledText>
          </li>
          <li>
            <StyledText>
              To protect and defend the rights or property of Perk Inc.
            </StyledText>
          </li>
          <li>
            <StyledText>
              To prevent or investigate possible wrongdoing in connection with
              the Service
            </StyledText>
          </li>
          <li>
            <StyledText>
              To protect the personal safety of users of the Service or the
              public
            </StyledText>
          </li>
          <li>
            <StyledText>To protect against legal liability</StyledText>
          </li>
        </ul>

        <Title>Security Of Data</Title>
        <StyledText>
          The security of your data is important to us, but remember that no
          method of transmission over the Internet, or method of electronic
          storage is 100% secure. While we strive to use commercially acceptable
          means to protect your Personal Data, we cannot guarantee its absolute
          security. Service Providers We may employ third party companies and
          individuals to facilitate our Service ("Service Providers"), to
          provide the Service on our behalf, to perform Service-related services
          or to assist us in analyzing how our Service is used. These third
          parties have access to your Personal Data only to perform these tasks
          on our behalf and are obligated not to disclose or use it for any
          other purpose.
        </StyledText>

        <SubTitle>Analytics</SubTitle>
        <StyledText>
          We may use third-party Service Providers to monitor and analyze the
          use of our Service.
        </StyledText>

        <StyledText>
          <b>Google Analytics</b>
        </StyledText>
        <StyledText>
          Google Analytics is a web analytics service offered by Google that
          tracks and reports website traffic. Google uses the data collected to
          track and monitor the use of our Service. This data is shared with
          other Google services. Google may use the collected data to
          contextualize and personalize the ads of its own advertising network.
          You can opt-out of having made your activity on the Service available
          to Google Analytics by installing the Google Analytics opt-out browser
          add-on. The add-on prevents the Google Analytics JavaScript (ga.js,
          analytics.js, and dc.js) from sharing information with Google
          Analytics about visits activity. For more information on the privacy
          practices of Google, please visit the Google Privacy & Terms web page:
          https://policies.google.com/privacy?hl=en
        </StyledText>

        <Title>Links To Other Sites</Title>
        <StyledText>
          Our Service may contain links to other sites that are not operated by
          us. If you click on a third party link, you will be directed to that
          third party's site. We strongly advise you to review the Privacy
          Policy of every site you visit. We have no control over and assume no
          responsibility for the content, privacy policies or practices of any
          third party sites or services.
        </StyledText>

        <Title>Children's Privacy</Title>
        <StyledText>
          Our Service does not address anyone under the age of 18 ("Children").
          We do not knowingly collect personally identifiable information from
          anyone under the age of 18. If you are a parent or guardian and you
          are aware that your Children has provided us with Personal Data,
          please contact us. If we become aware that we have collected Personal
          Data from children without verification of parental consent, we take
          steps to remove that information from our servers.
        </StyledText>

        <Title>Changes To This Privacy Policy</Title>
        <StyledText>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page. We will
          let you know via email and/or a prominent notice on our Service, prior
          to the change becoming effective and update the "effective date" at
          the top of this Privacy Policy. You are advised to review this Privacy
          Policy periodically for any changes. Changes to this Privacy Policy
          are effective when they are posted on this page.
        </StyledText>

        <Title>Contact Us</Title>
        <StyledText style={{ marginBottom: 0 }}>
          If you have any questions about this Privacy Policy, please contact
          us:
        </StyledText>
        <StyledText>
          By email: <a href="mailto:contact@perk.com">contact@perk.com</a>
        </StyledText>
      </ModalContentContainer>
    </Modal>
  );
};

export { PrivacyPolicy };
