import styled from 'styled-components';
import { ThemeColors } from '../../types';
import { Button } from '../Button';
import { FlexBlock } from '../Layout';

type TextButtonProps = {
  color?: keyof ThemeColors;
  weight?: number;
  padding?: string;
};

export const TextButton = styled(Button)<TextButtonProps>`
  background: none;
  color: ${(props) => props.theme.colors[props.color || 'highlight']};
  border: 0;
  padding: ${(props) => props.padding || 0};
  font-weight: ${(props) => props.weight || 600};
  width: auto;
  min-width: 0;
  height: auto;
`;

export const FailedButtonsRow = styled(FlexBlock)`
  justify-content: flex-end;
  ${TextButton} + ${TextButton} {
    margin-left: 1.2em;
  }
`;
