import styled from 'styled-components';

export const Left = styled.div``;

export const Title = styled.span`
  font-weight: 600;
  color: ${props => props.theme.colors.C1};
  font-size: ${props => props.theme.typography.H4}px;
  white-space: nowrap;
`;

export const Description = styled.div`
  color: ${(props) => props.theme.colors.C2};
  margin-top: ${props => props.theme.spacings.xs}px;
  font-size: ${props => props.theme.typography.H5}px;
  font-weight: 400;
  white-space: nowrap;
`;

export const DescriptionSuccess = styled(Description)`
  color: ${(props) => props.theme.colors.B1};
`;

export const DescriptionError = styled(Description)`
  color: ${(props) => props.theme.colors.error};
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;

  ${Title} + ${Description} {
    margin-top: ${props => props.theme.spacings.xs}px;
  }
`;

export const Container = styled.div`
  display: flex;
  width: auto;

  ${Left} + ${Right} {
    margin-left: ${props => props.theme.spacings.md}px;
  }
`;

export const ProgressContainer = styled.div`
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  svg {
    #progress-spin {
      transform-origin: center center;
      animation-name: spin;
      animation-duration: 1000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
`;
