import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const NavLink = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 ${(props) => props.theme.spacings.md}px;}  
  color: ${(props) => props.theme.colors.C2};
  font-size: ${(props) => props.theme.typography.H4}px;

  svg {
    margin-right: ${(props) => props.theme.spacings.xs}px;
  }

  &.active {
    color: ${(props) => props.theme.colors.B1};
    font-weight: 600;

    svg 
      path {
      fill: ${(props) => props.theme.colors.B1};
    }
  }
  
`;
